<template>
  <header class="container-fluid">
        <div class="row">
            <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-in-color" aria-label="Offcanvas navbar large" id="lugar">

                <div class="container">
                    <a class="navbar-brand" @click="$router.push({ name :'principal',params:{}});" style="cursor: pointer;">
                        <img id="navbar-logo" :src="require('@/assets/images/logo_zumba.png')" alt="ZumbaW" height="50" /></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar2"
                        aria-labelledby="offcanvasNavbar2Label">
                        <div class="offcanvas-header" >
                            <a class="navbar-brand" @click="$router.push({ name :'principal',params:{}});" style="cursor: pointer;">
                                <img id="navbar-logo" :src="require('@/assets/images/logo_zumba_dark.png')" alt="ZumbaB" height="50" />
                            </a>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <ul class="navbar-nav justify-content-end flex-grow-1">
                                <li class="nav-item active" v-for="(item, index) in ListaCategorias" :key="index" @click="viewListProducto(item.IdCategoria,0,'')">
                                    <a v-if="index < 4" class="nav-link" data-bs-dismiss="offcanvas" style="cursor: pointer" >{{ item.Nombre }}</a>
                                </li>
                               
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        Categorias
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li v-for="(item, index) in ListaCategorias" :key="index">
                                            <a v-if="index >= 4" class="dropdown-item" data-bs-dismiss="offcanvas" style="cursor: pointer" 
                                                @click="viewListProducto(item.IdCategoria,0,'')">{{ item.Nombre }}</a>
                                        </li>
                                       
                                    </ul>
                                </li>
                                
                                <li class="nav-item">
                                    <a class="nav-link" style="cursor: pointer;" @click="viewEventos()" data-bs-dismiss="offcanvas">Eventos</a>
                                </li>
                                <li class="nav-item">
                                    <a v-if="!$store.getters.isLoggedIn" class="nav-link" @click="goLogin()" style="cursor: pointer;">Ingresar/Registro</a>
                                    <a v-else class="nav-link" @click="goPerfil()" style="cursor: pointer;" data-bs-dismiss="offcanvas" >Mi Perfil</a>
                                </li>
                            </ul>
                            <form class="d-flex d-lg-none mt-3 mt-lg-0" role="search">
                                <input class="form-control me-2" type="search" placeholder="Buscar producto" v-model="txtBuscador"
                                    aria-label="buscar" />
                                <button @click="iniciarBusqueda()" class="btn btn-primary" type="button" >
                                    <i class="fas fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="icons d-flex align-items-center text-white">
                        <a @click="goBolsa()" style="cursor: pointer;">
                            <i class="far fa-shopping-bag fs-6 mx-0 mx-2" title="Bolsa"></i>
                        </a>
                        
                        <button class="btn-search d-none d-lg-flex align-items-center me-2" @click="openBuscador()">
                            <i class="fas fa-search text-dark"></i>
                        </button>
                    </div>
                </div>

            </nav>
        </div>


        <CModalBuscador :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			
		</CModalBuscador>


    </header>
    <!--Menu-->
</template>

<script>
import CModalBuscador from '../../components/CModalBuscador.vue';

const EmitEjecuta = 'seccionNavBuscador';
export default {
  name: 'NavLinks',
  components:{
    CModalBuscador
  },

    data() {
        return {
            //logoBlanco:  require('@/assets/images/logo_zumba.png'),
            //logoBlack: require('@/assets/images/logo_zumba_dark.png'),

            ListaCategorias: [],
            firstCharge: false,
            isBlack: false,
            logueado: false,
            txtBuscador: '',


            ConfigModal:{
                ModalTitle: "",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta,
                // Obj:{objProductoPresentacion:{}},
                ModalSize:'lg',
                ShowTittle: false,
                ShowFooter: false,
                ConfigModal: false,
                FullScreen: true
            },
            oBtnSave: {
                Toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },
            tipoMenu: 'auto'
        };
    },
    methods: {
        navBarControl() {
            

            window.onscroll = function() {
                let scroll =  window.scrollY;
                var URLactual = window.location;
                   
                if(URLactual.pathname != '/home'){
                    $("#lugar")
                        .removeClass("bg-in-color")
                        .addClass("bg-color");
                    $(".icons i")
                        .removeClass("text-light")
                        .addClass("text-dark");
                    $("#navbar-logo").attr("src",require('@/assets/images/logo_zumba_dark.png'));
                }else{
                
                    if (scroll > 60) {
                        $("#lugar")
                            .removeClass("bg-in-color")
                            .addClass("bg-color");
                        $(".icons i")
                            .removeClass("text-light")
                            .addClass("text-dark");
                        $("#navbar-logo").attr("src",require('@/assets/images/logo_zumba_dark.png'));
                        
                    } else {
                        $("#lugar")
                            .removeClass("bg-color")
                            .addClass("bg-in-color");
                        $(".icons i")
                            .removeClass("text-dark")
                            .addClass("text-light");
                        $("#navbar-logo").attr("src",require('@/assets/images/logo_zumba.png'));
                    }
                }
                
                /*$(".simple-timer").syotimer({
                    date: new Date(2024, 10, 21, 15),
                    lang: {
                        second: ["seg", "seg"],
                        minute: ["min", "min"],
                        hour: ["horas", "horas", "horas"],
                        day: ["dia", "dias", "dias"],
                    }
                });*/
            };
        },
        
        getCategorias() {
            this.ListaCategorias = [];
            //if(!this.firstCharge) {
                this.$http.get('categorias', {
                    params: {
                        isSimple: 1,
                        onlyPrincipales: 1,
                        Or:'sh'
                    }

                }).then((res) => {
                    //console.log(res.data);
                    res.data.data.forEach(element => {
                        
                        this.ListaCategorias.push({
                            IdCategoria: element.IdCategoria,
                            Nombre: element.Nombre,
                            Asociado: element.Asociado,
                            Icono: element.Icono,
                        });
                    });

                    sessionStorage.setItem('categorias',JSON.stringify(this.ListaCategorias));
                    sessionStorage.setItem('iconCat',res.data.rutaIcono);
                    sessionStorage.setItem('ImgCat',res.data.rutaFile);
                    /*if (this.ListaCategorias.length > 0) {

                        var IdCategoria = this.ListaCategorias[0].IdCategoria;
                        var Categoria   = this.ListaCategorias[0].Categoria;
                        //this.get_Productos(IdCategoria, Categoria);
                    }*/

                }).catch(err => {
                    console.log(err);
                });
           // }
        },

        openBuscador(){
            this.bus.$emit('NewModal_'+EmitEjecuta);
            this.bus.$emit('HideFooter_'+EmitEjecuta);
        },

        cambiaBlack() {
            
            $("#lugar")
                .removeClass("bg-in-color")
                .addClass("bg-color");
            $(".icons i")
                .removeClass("text-light")
                .addClass("text-dark");
            $("#navbar-logo").attr("src",require('@/assets/images/logo_zumba_dark.png'));
        },
        
        cambiaBlanco() {
            $("#lugar")
                .removeClass("bg-color")
                .addClass("bg-in-color");
            $(".icons i")
                .removeClass("text-dark")
                .addClass("text-light");
            $("#navbar-logo").attr("src",require('@/assets/images/logo_zumba.png'));
        },

        goHome(){
            this.$router.push({name:'principal',params:{} });
        },

        goLogin() {
            document.getElementById('pageBody').removeAttribute('style');

            this.$router.push({name:'login',params:{} });
        },

        goBolsa(){
            this.$router.push({name:'bolsa',params:{} });
        },

        goPerfil(){
            this.$router.push({name:'profile',params:{} });
        },

        iniciarBusqueda() {
            this.viewListProducto(0,0,this.txtBuscador);
        },

        viewListProducto(idCat = 0,isTop = 0,textbusqueda = '') {

            let busqueda = '';
            if(textbusqueda != '' && textbusqueda != undefined ) {
                busqueda = textbusqueda;
            }else {
                busqueda = '';
            }

            sessionStorage.setItem('PositionProduct','');
            sessionStorage.setItem('currentPage','1');
                
            if(this.$route.name == "productos"){

                let parametros = {
                        pIdCategoria: idCat, 
                        pTextBusqueda: busqueda,
                        pIsTop: isTop,
                    };

                this.bus.$emit('CambiarCategoria2',parametros);

            }else{

                this.$router.push({ name :'productos',params:{
                        pIdCategoria: idCat, 
                        pTextBusqueda: busqueda,
                        pIsTop: isTop, 
                        
                    }
                });
            }
        },

        viewEventos() {
            this.$router.push({name:'eventos',params:{} });
        }
            
        
    },

    created(){
        this.bus.$off('cambiaLogoNegro');
        this.bus.$off('cambiaLogoBlanco');
        this.logueado = this.$store.getters.isLoggedIn;
    },
    mounted() {
        
        this.getCategorias();
        this.navBarControl();

        this.bus.$on('cambiaLogoNegro',()=>
        {
            this.cambiaBlack();
        });

        this.bus.$on('cambiaLogoBlanco',()=>
        {
            this.cambiaBlanco();
        });
    },

  
};
</script>